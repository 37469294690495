


$(function() {

  $('#wyloguj-btn').click( function () {

    var pdata = {

    }

    $.post( "/post/logout/", pdata, function( data ) {

      location.reload();

    }).done( function () {

    }).fail(function () {

    });

  });

});





