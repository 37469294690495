

// console.info('galeria start');

$(function() {



  $ ( '#gal-zaznacz-wszystkie' ).click ( function () {

    console.info ( 'zaznacz wszystkie' );
    $('.foto-zaznacz').each( function() {
      $(this).prop( "checked", true );
    });
    handleZaznacz();

  } );


  $ ( '#gal-dodaj-zaznaczone' ).click ( function () {

    console.info ( 'dodaj-zaznaczone' );
    let quality = Number($('#jakosc-wybrane').val());
    dodajWiele(quality);

  } );


  $ ( '.foto-zaznacz, #jakosc-wybrane' ).on ( 'change', function () {

    // let checked = $ ( this ).is ( ':checked' );
    // console.info ( 'checked', checked );
    handleZaznacz();

  } );




  function handleZaznacz() {

    let zaznaczone = 0;
    let quality = Number($('#jakosc-wybrane').val());
    $('.foto-zaznacz').each( function() {
      let checked = $ ( this ).is ( ':checked' );
      if (checked && quality>0) {
        $('#gal-dodaj-zaznaczone').removeClass('btn-danger');
        $('#gal-dodaj-zaznaczone').addClass('btn-success');
        zaznaczone++;
      }
    });
    console.info('zzzz', zaznaczone);
    if (zaznaczone === 0) {
      $('#gal-dodaj-zaznaczone').removeClass('btn-success');
      $('#gal-dodaj-zaznaczone').addClass('btn-danger');
    }

  }



  function dodajWiele ( fotoquality ) {

    let zaznaczoneID = [];
    $ ( '.foto-zaznacz' ).each ( function () {
      let checked = $ ( this ).is ( ':checked' );
      if (checked) {
        zaznaczoneID.push( Number($(this).attr('rel')) );
      }
    } );

    if (fotoquality === 0 || zaznaczoneID.length < 1) {
      console.info(fotoquality, zaznaczoneID.length);
      return false;
    }

    let data = {
      'fotosid': zaznaczoneID,
      'galleryid': galleryID,
      'fotoquality': fotoquality,
      'galleryhash': galleryHash
    };

    var jqxhr = $.post ( "/post/updatecart/more", data, function ( ret ) {

      // console.info ( 'cart ret', ret );

      let x = JSON.parse ( ret );
      // console.info ( "Data Loaded: " + x );
      // console.dir ( x );

      $ ( '#topbarcartnumber' ).html ( x['cartitemscount'] );
      $ ( '#topbarcartnumberspace' ).show ();
      $ ( '#koszyk-ikona' ).removeClass ( 'btn-secondary' );
      $ ( '#koszyk-ikona' ).addClass ( 'btn-primary' );

      $ ( '#topbarcartnumber-mob' ).html ( x['cartitemscount'] );
      $ ( '#topbarcartnumberspace-mob' ).show ();
      $ ( '#koszyk-ikona-mob' ).removeClass ( 'btn-secondary' );
      $ ( '#koszyk-ikona-mob' ).addClass ( 'btn-primary' );

      var afterClose = () => {
        //console.info('after modal close')
        //console.dir(x);
      };
      var afterSave = () => {
        console.info ( 'saved' );
      };

      window.location.href = '/koszyk/';


    } )
      .done ( function ( ret ) {
        //alert( "second success" );
      } )
      .fail ( function ( ret ) {
        console.info(ret);
        alert ( "error" + ret );
      } )
      .always ( function ( ret ) {
        //alert( "finished" );
      } );

  }


});








