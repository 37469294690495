


function createPayment(type) {



  if (! $('#zgoda-regulamin').is(':checked')) {

    $('#zaakceptuj-regulamin-info').show();
    window.scrollTo(0,0);
    return false;

  }

  var data = { 'type': type };
  var jqxhr = $.post( "/post/createorder/", data, function(ret) {

    console.info( "success", JSON.parse(ret) );
    var rr = JSON.parse(ret);
    if (rr['error']!=='false') {
      alert(rr['error']);
      return false;
    }
    if (rr['type'] === 'dotpay') {

      // console.info('dotpay!!!');
      $('body').append( rr['form'] );
      $('#dotpay_redirection_form').submit();

    }
    else if (rr['type'] === 'przelew') {
      window.location.assign('/zamowienie/' + rr['hash']);
    }

  })
    .done(function() {
      console.info( "second success" );
    })
    .fail(function() {
      console.info( "error" );
    })
    .always(function() {
      console.info( "finished" );
    });

}





$(function () {

  $('body').on('change', '#zgoda-regulamin', function () {

    if ( $('#zgoda-regulamin').is(':checked') ) {

      $('#zaakceptuj-regulamin-info').hide();

    }

  });

  $('body').on('click','#customRadio1', function () {

    $('#platnosc-btnout-2').hide();
    $('#platnosc-btnout-1').show();

  });



  $('body').on('click','#customRadio2', function () {

    $('#platnosc-btnout-1').hide();
    $('#platnosc-btnout-2').show();


  });

  $('body').on('click','#platnosc-btn-1', function () {

    console.info('dotpay platnosc');
    createPayment('dotpay');

  });


  $('body').on('click','#platnosc-btn-2', function () {

    console.info('platnosc przelewem');
    createPayment('przelew');

  });



});
