
import { dom, library } from '@fortawesome/fontawesome-svg-core'

const addEventForChild = function(parent, eventName, childSelector, cb){
  parent.addEventListener(eventName, function(event){
    const clickedElement = event.target,
      matchingChild = clickedElement.closest(childSelector)
    if (matchingChild) cb(matchingChild,event)
  })
};



var agiZoomSelectedGallery = null;
var agiZoomSelected = null;
var agiZoomMax = 0;

var selector = 'gal-photo';
var photos = [];

var galleries = [];
var gpnum = [];




function photoLeft() {

  agiZoomSelected--;
  if (agiZoomSelected<1) {

    agiZoomSelected = gpnum[agiZoomSelectedGallery];

  }



  const imgUrl = $('[data-gallery="'+agiZoomSelectedGallery +'"][data-gallery-num="'+agiZoomSelected+'"]').attr('data-image');
  const imgUrlCss = "url('"+ imgUrl +"')";


  $('#agiZoomFot').css('backgroundImage', imgUrlCss);
  $('#agiZoomFot').css('opacity', 1);
  // console.info(imgUrl);

  $('#agiZoomFot2').animate({
    'opacity': 0
  },500, ()=>{
    $('#agiZoomFot2').css('backgroundImage', imgUrlCss);
    setTimeout( ()=>{
      $('#agiZoomFot2').css('opacity', 1);
    }, 100);
  });


}



function photoRight() {

  agiZoomSelected++;
  if (agiZoomSelected===gpnum[agiZoomSelectedGallery]+1) {

    agiZoomSelected = 1;

  }

  const imgUrl = $('[data-gallery="'+agiZoomSelectedGallery +'"]').filter('[data-gallery-num="'+agiZoomSelected+'"]').attr('data-image');
  const imgUrlCss = "url('"+ imgUrl +"')";
  $('#agiZoomFot').css('backgroundImage', imgUrlCss);

  $('#agiZoomFot2').stop(true).animate({
    'opacity': 0
  },500, ()=>{
    $('#agiZoomFot2').css('backgroundImage', imgUrlCss);
    setTimeout( ()=>{
      $('#agiZoomFot2').css('opacity', 1);
    }, 100);
  });

}




function photoClose() {

  $('#agiZoom').stop(true).animate({
    'opacity': 0
  },1000, ()=>{

    $('#agiZoom').remove();

  });

}




export function init(selectorClass) {

  selector = selectorClass;
  photos = [...document.getElementsByClassName(selector)];


  photos.forEach( (el)=> {

    const dg =  el.getAttribute('data-gallery');
    if (typeof galleries[dg] === "undefined") {
      galleries[dg] = [];
      gpnum[dg] = 1;
    } else {
      gpnum[dg]++;
    }
    galleries[dg].push(el);
    el.setAttribute('data-gallery-num', gpnum[dg]);

  });

  addEventForChild(parent, 'click', '.'+selector,(el, event)=>{

    event.preventDefault();
    const img = el.getAttribute('data-image');
    const gName = el.getAttribute('data-gallery');
    const znum = galleries[gName].length;

    var zhtml = '<div id="agiZoom"><div id="agiZoomBg"></div><div id="agiZoomFot" style="background-image: url(' + img + ')"><div id="agiZoomFot2" style="background-image: url(' + img + ')"></div></div>';
    $('body').append(zhtml);
    var zclosehtml = '<div id="agizoom-close"><i fa class="fas fa-times-circle"></i></div>';
    $('#agiZoom').append(zclosehtml);

    if ( znum > 1 ) {

      var zahtml = '<div class="align-items-center row h-100 agizoom-arrows" id="agiZoomArrows"></div>';
      $('#agiZoom').append(zahtml);
      var arrLeft = '<div class="col-2 text-center" id="agizoom-left"><i fa class="fas fa-angle-double-left"></i></div>';
      var arrRight = '<div class="col-2 offset-8 text-center" id="agizoom-right"><i fa class="fas fa-angle-double-right"></i></div>';
      $('#agiZoomArrows').append( arrLeft );
      $('#agiZoomArrows').append( arrRight );

    }

    $('#agiZoom').animate({
      'opacity': 1
    }, 1000);

    agiZoomSelected = el.getAttribute('data-gallery-num');
    agiZoomSelectedGallery = gName;

    dom.i2svg(); // find <i> in html and change to icons

  });




  $('body').on('click', '#agizoom-left', function () {


    photoLeft();

  });

  $('body').on('click', '#agizoom-right', function () {


    photoRight();
    // console.info(imgUrl);

  });

  $('body').on('click', '#agizoom-close', function () {

    photoClose();

  });
  
  $('body').on('keyup', function (ev) {

    if( $('#agiZoom').length ) {

      console.info(ev.which);
      if (ev.which === 39) {

        photoRight();

      } else  if (ev.which === 37) {

        photoLeft();

      }
      else if (ev.which === 27) {

        photoClose();

      }

    }

  });







}














