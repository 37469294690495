

console.info('f1', window.fotoID);

var fotoquality = 3;
if ( typeof fotoID === 'undefined' ) {
  if (typeof window.fotoID !== 'undefined') {
    var fotoID = window.fotoID;
  }
  else {
    var fotoID = 0;
  }
}
if ( typeof galleryID === 'undefined' ) {
  if ( typeof window.galleryID !== 'undefined' ) {
    var galleryID = window.galleryID;
  }
  else {
    var galleryID = 0;
  }
}
if ( typeof galleryHash === 'undefined' ) {
  if ( typeof window.galleryHash !== 'undefined' ) {
    var galleryHash = window.galleryHash;
  }
  else {
    var galleryHash = "x";
  }
}

console.info('f2', fotoID);

$(function() {


  /**
   *
   */
  $('#buynow').click( function () {


    let data = {
      'fotoid': fotoID,
      'galleryid': galleryID,
      'fotoquality': fotoquality,
      'galleryhash': galleryHash
    };

    var jqxhr = $.post( "/post/updatecart/", data, function(ret) {

      let x = JSON.parse(ret);
      //console.info( "Data Loaded: " +  x);
      //console.dir(x);



    })
      .done(function(ret) {
        //alert( "second success" );
        window.location.href = '/koszyk/';
      })
      .fail(function(ret) {
        alert( "error" + ret  );
      })
      .always(function(ret) {
        //alert( "finished" );
      });

  } );






  $('#add2cart').click( function () {


    let data = {
      'fotoid': fotoID,
      'galleryid': galleryID,
      'fotoquality': fotoquality,
      'galleryhash': galleryHash
    };

    var jqxhr = $.post( "/post/updatecart/", data, function(ret) {

      console.info('cart ret', ret);

      let x = JSON.parse(ret);
      console.info( "Data Loaded: " +  x);
      console.dir(x);

      $('#topbarcartnumber').html( x['cartitemscount'] );
      $('#topbarcartnumberspace').show();
      $('#koszyk-ikona').removeClass('btn-secondary');
      $('#koszyk-ikona').addClass('btn-primary');

      $('#topbarcartnumber-mob').html( x['cartitemscount'] );
      $('#topbarcartnumberspace-mob').show();
      $('#koszyk-ikona-mob').removeClass('btn-secondary');
      $('#koszyk-ikona-mob').addClass('btn-primary');

      var afterClose = ()=> {
        //console.info('after modal close')
        //console.dir(x);
      };
      var afterSave = ()=> {
        console.info('saved');
      };

      showModal(
        false,
        false,
        '',
        '<div class="text-center">Obraz dodany do koszyka</div>',
        false,
        true,
        2000,
        afterClose,
        afterSave);

    })
      .done(function(ret) {
        //alert( "second success" );
      })
      .fail(function(ret) {
        alert( "error" + ret  );
      })
      .always(function(ret) {
        //alert( "finished" );
      });

  } );








  $('.fotoq').click( function () {

    fotoquality = $(this).val();
   // console.info('', fotoquality);

  } );






});
