


$(function() {

  $('#login-submit').click( function () {

    var pdata = {
      'email' : $('#login-email').val(),
      'haslo' : $('#login-pass').val()
    }

    $.post( "/post/login/", pdata, function( data ) {

      data = JSON.parse(data);

      console.info('d:', data);
      if (data['result'] === true) {

        location.reload();

      } else {

        console.info('login err');
        //$('#login-info').show();
        $('#login-info').css('display', 'inline');

      }


    }).done( function (data) {



    }).fail(function () {

    });

  });


  $('#login-email, #login-pass').focus( function () {

    $('#login-info').css('display', 'none');

  });

  $('#login-email, #login-pass').keydown( function () {

    $('#login-info').css('display', 'none');

  });

});

