
import * as agiZoom from '../js-libs/agizoom';

var mouseX;
var mouseY;



//console.info('agi-main-start');
$(function() {
  console.log( "ready!" );

  $('[data-toggle="tooltip"]').tooltip();

  // pierwsza strona zdjecia tla

  agiZoom.init('gal-photo');


  if ( typeof bgfiles === 'object') {

    for ( const [i,v] of bgfiles.entries()) {
      $( "#page0" ).prepend( `<div class="bg" id="bg${i}"></div>` );
      $('#bg' +i).css('backgroundImage', `url("${v}")`);
      //console.info('v',v);
    }


    let rotateBG = setInterval( ()=> {
      $('.bg').last().fadeOut(400, ()=>{
        const lastBG = $('.bg').last();
        $('.bg').last().remove();
        $('#page0').prepend(lastBG);
        $('.bg').first().show();

      });
    },5000);

  }


  console.info('show');
  $('.oferta-page').hide();
  $('#agi-outer').delay(250).show();
  $('.oferta-page').fadeIn(100);


  // GALERIA LISTA


  $(document).mousemove( function(e) {
    // mouse coordinates
    mouseX = e.pageX;
    mouseY = e.pageY;

  });

// hover

  $('#tooltip').mouseover( function() {


    $('#tooltip').css({'top':mouseY + 120,'left':mouseX - 100});
    $('#tooltip').stop(false, true).fadeIn(10);


  });

  $(".hover").mouseover(function(){
    // populate tooltip string
    $('#tooltip span').html(stringValue($(this)));

    // show tooltip
    $('#tooltip').stop(false, true).fadeIn(1);

    // position tooltip relative to mouse coordinates
    $(this).mousemove(function() {
      $('#tooltip').css({'top':mouseY - 120,'left':mouseX - 100});
    });
  }).mouseout(function() {
    // hide tooltip
    $('#tooltip').stop(false, true).fadeOut('slow');
  });

// perform check and return string
  function stringValue(e) {
    let r = e.attr('data-hover').split('|');
    let rr = 'ID: F' + galleryID + 'G' + r[4];
    if (r[0].length>0) {
      rr += '<br>' + 'Nazwa: ' + r[0];
    }
    rr += '<br>' + 'Cena A: ' + r[1] + ' zł';
    rr += '<br>' + 'Cena B: ' + r[2] + ' zł';
    rr += '<br>' + 'Cena C: ' + r[3] + ' zł';

    return rr;
  }



  $('.aktualnosci-box').click( function () {

    let rel = $(this).attr('rel');
    window.location.assign('/aktualnosci/' + rel + '/');

  });













});


