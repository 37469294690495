


/**
 *
 * @param header {boolean}
 * @param footer {boolean}
 * @param title {string}
 * @param body {string}
 * @param closeBtn {boolean}
 * @param saveBtn {boolean}
 * @param autoClose {int}
 * @param afterClose {function}
 * @param afterSave {function}
 */
function showModal(header, footer, title,body,closeBtn,saveBtn,autoClose, afterClose, afterSave) {

  if (!header) {
    $('#agiModalHeader').css('display','none');
  } else {
    $('#agiModalHeader').css('display','block');
  }

  if (!footer) {
    $('#agiModalFooter').css('display','none');
  } else {
    $('#agiModalFooter').css('display','block');
  }

  if (title==='') {
    $('#agiModalLabel').css('display','none');
  } else {
    $('#agiModalLabel').css('display','block');
    $('#agiModalLabel').html(title);
  }

  if (!closeBtn) {
    $('#agiModalCloseBtn').css('display','none');
  } else {
    $('#agiModalCloseBtn').css('display','inline-block');
  }

  if (!saveBtn) {
    $('#agiModalSaveBtn').css('display','none');
  } else {
    $('#agiModalSaveBtn').css('display','inline-block');
  }


  $('#agiModalBody').html(body);
  $('#agiModal').modal('show');

  $("#agiModal").off('hidden.bs.modal');
  if (afterClose) {
    $('#agiModal').on('hidden.bs.modal', afterClose);
  }

  $('#agiModalSaveBtn').off();
  if (afterSave) {
    $('#agiModalSaveBtn').on('click', afterSave);
  }


  if (autoClose>0) {

    setTimeout( ()=> {

      $('#agiModal').modal('hide');

    }, autoClose);

  }

}















