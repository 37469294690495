var formValid = 0;

$(function () {

  $('#submit-not-ready').show();

  $('body').on('change','#kontakt_email', function () {

    checkemail();

  });



  $('#form-new-klient input').on('change', function (ev) {

    // const el = $('#'+ev.target.id);
    let ch = checkform('form-new-klient');


  });


  $('#submit-not-ready').on('click', function (ev) {

    console.info('clicked not ready');
    let ch = checkform('form-new-klient');
    console.info('ch', ch);
    if (ch) {
      submitForm();
    }

  });


  $('#submit-ready').on('click', function (ev) {

    console.info('clicked ready');
    let ch = checkform('form-new-klient');
    console.info('ch', ch);

    if (ch) {
      submitForm();
    }

  });


});






function checkemail( ) {

  let ok = false;
  var pdata = { shb: "checkemail", email: $('#kontakt_email').val() };

  var jqxhr = $.post( "/post/checkclient/", pdata, function() {
    //alert( "success" );
  })
    .done(function(data) {
      //alert( "second success" );

      console.info('response', data);
      let dt = JSON.parse(data);
      console.info('dt', dt);
      if (dt['ok']!=='ok') {
        console.info('show');
        $('#email-info').removeClass('d-none');
        $('#email-info').show();
      } else {
        $('#email-info').hide();
        ok = true;
      }


    })
    .fail(function() {
      //alert( "error" );
    })
    .always(function() {
      //alert( "finished" );
    });

}





/**
 *
 * @param id {string} id of the form element
 * @returns {boolean}
 */
function checkform(id) {

  $('#'+id+' label').css('backgroundColor','');
  $('#'+id+' label').css('color','#000000');

  let valid = true;
  const elb =$('#'+id+' input');
  elb.each(function (i, elx) {

    //console.info(elx);
    const el = $('#'+elx.id);
    const req = el.attr('required');
    if (req === 'required' && el.val().length === 0) {
      console.info('eeee');
      $('label[for="'+elx.id+'"]').css('backgroundColor','#ff0000');
      $('label[for="'+elx.id+'"]').css('color','#ffffff');
      valid = false;

    }

  });

  if (valid) {
    $('#submit-ready').show();
    $('#submit-not-ready').hide();
  } else {
    $('#submit-ready').hide();
    $('#submit-not-ready').show();
  }

  return valid;

}



function submitForm() {

  console.info('dodaję konto...');

  var pdata = {

    shb: "addemail",
    email: $('#kontakt_email').val(),
    haslo: $('#haslo').val(),
    kontakt_imie: $('#kontakt_imie').val(),
    kontakt_nazwisko: $('#kontakt_nazwisko').val(),
    kontakt_telefon: $('#kontakt_telefon').val()

  };

  var jqxhr = $.post( "/post/addclient/", pdata, function() {
    //alert( "success" );
  })
    .done(function(data) {
      //alert( "second success" );

      console.info('response', data);
      let dt = JSON.parse(data);
      console.info('dt', dt);
      if (dt['ok']==='ok') {
        console.info('ok added');

        if (zkoszyka === 'ok') {
          window.location.assign('/koszyk/');
        }
        else if ( zkoszyka.substr(0,2) === 'g/' ) {
          window.location.assign( '/' + zkoszyka + '/' );
        }
        else {
          window.location.assign('/konto/');
        }


      } else {



      }


    })
    .fail(function() {
      //alert( "error" );
    })
    .always(function() {
      //alert( "finished" );
    });



}















